import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { AxisBottom } from '@visx/axis';

import { useRef } from 'react';
import classNames from 'classnames';
import { isArray, uniq } from 'lodash';

import { CheckboxWithLabel } from '@/components/form/checkbox';
import { formatNumberToCompact } from '@/lib/utils/number';

import { NoMatchBanner } from '@/components/banner';

import { CategoryData, GroupedChartData } from '../grouped-chart/types';
import { ColoredLegend } from '../legend';
import { GroupedChart } from '../grouped-chart';

import {
  getMaxStackedValue,
  getMaxValue,
  getMinStackedValue,
  getMinValue,
  getSeries,
  getValueKeys,
} from '../grouped-chart/utils';

import { HorizontalBarChart } from './horizontal-bar-chart';

import { StackedHorizontalBarChart } from './stacked-horizontal-bar-chart';

import commonStyles, {
  barColor,
  barColor1,
  barColor2,
  barColor3,
  barColor4,
  barColor5,
  barColor6,
} from '../charts.module.scss';
import styles from './grouped-horizontal-chart.module.scss';

const ValuePaddingCoef = 0.1;
const WidthPerGroup = parseInt(commonStyles.widthPerGroup);
const MinChartWidth = 50;
const MinChartHeight = 50;

type GroupedHorizontalBarChartProps = {
  data: GroupedChartData<CategoryData>;
  grouping: { key: string; label: string }[];
  stacked?: boolean;
  roundTickValues?: boolean;
  isResized?: boolean;
  isTableVisible?: boolean;
  isNotFullData?: boolean;
  setStacked?: (stacked: boolean) => void;
  onCategroryClick?: (event: React.MouseEvent, key: string, value: any) => void;
};

export const GroupedHorizontalBarChart = ({
  data,
  grouping,
  stacked = false,
  roundTickValues = false,
  isResized = false,
  isTableVisible = true,
  isNotFullData = false,
  setStacked,
  onCategroryClick,
}: GroupedHorizontalBarChartProps) => {
  const seriesDomain = getValueKeys(data);
  const footerRef = useRef<HTMLDivElement>(null);

  const colorScale = scaleOrdinal<string, string>({
    domain: seriesDomain,
    range:
      seriesDomain.length === 1
        ? [barColor]
        : [barColor1, barColor2, barColor3, barColor4, barColor5, barColor6],
  });

  const legend = getSeries(data).map(({ key, label }) => ({
    label,
    color: colorScale(key),
  }));

  const isStackable = legend.length > 1;
  const groupCount = grouping.length;
  const enableResize = groupCount < 3;

  return (
    <ParentSize
      debounceTime={0}
      className={classNames(
        { [commonStyles.singleChart]: enableResize },
        { [commonStyles.customHeight]: !isTableVisible && isResized },
      )}>
      {(parent) => {
        const groupsWidth = WidthPerGroup * Math.max(1, grouping.length - 1);
        const chartWidth = Math.max(MinChartWidth, parent.width - groupsWidth);

        const maxVal = stacked ? getMaxStackedValue(data) : getMaxValue(data);
        const minVal = Math.min(0, stacked ? getMinStackedValue(data) : getMinValue(data));

        const valueScale = scaleLinear<number>({
          range: [0, chartWidth],
          round: true,
          domain: [minVal + minVal * ValuePaddingCoef, maxVal + maxVal * ValuePaddingCoef],
        });

        const verticalSpacing = footerRef?.current?.clientHeight ?? 75;

        const chartHeight =
          enableResize && parent.height > 0
            ? Math.max(MinChartHeight, parent.height - verticalSpacing)
            : MinChartHeight;

        const tickValues = roundTickValues
          ? uniq(valueScale.ticks().map(Math.floor))
          : valueScale.ticks();

        const isPartialWarningShown = isNotFullData && !isArray(data);

        return (
          <div>
            <GroupedChart
              width={parent.width}
              widthPerGroup={WidthPerGroup}
              data={data}
              renderChart={(chartData, width) => {
                if (chartData.items.length === 0) {
                  return <NoMatchBanner />;
                }

                const chartProps = {
                  data: chartData,
                  width,
                  categoryFormat: { precision: 'month' },
                  colorScale,
                  valueScale,
                  onCategroryClick,
                  height: chartHeight,
                };
                const isStacked = stacked && chartData.series.length > 1;

                return isStacked ? (
                  <StackedHorizontalBarChart {...chartProps} />
                ) : (
                  <HorizontalBarChart {...chartProps} />
                );
              }}
            />
            <div ref={footerRef}>
              <div
                style={{
                  marginLeft: groupsWidth,
                }}>
                <svg width={chartWidth} height={30}>
                  <AxisBottom
                    scale={valueScale}
                    tickClassName={commonStyles.tickLabel}
                    tickLabelProps={() => ({ textAnchor: 'start' })}
                    tickFormat={(n) => formatNumberToCompact({ num: n.valueOf() })}
                    tickValues={tickValues}
                  />
                </svg>
              </div>
              <div className={styles.footer}>
                <div className={styles.partialDataWarning}>
                  {isPartialWarningShown ? `Showing first ${data.chartData.items.length}` : null}
                </div>
                <ColoredLegend items={legend} />
                {isStackable ? (
                  <CheckboxWithLabel
                    className={[commonStyles.stackingCheckbox]}
                    checked={stacked}
                    onChange={() => setStacked && setStacked(stacked !== true)}>
                    Stacked
                  </CheckboxWithLabel>
                ) : null}
              </div>
            </div>
          </div>
        );
      }}
    </ParentSize>
  );
};
