import { Node, Position, InternalNode } from '@xyflow/react';

import { Cell, Exploration, ExplorationParameters } from '@/explore/types';
import { PersistedOptions, CanvasNode } from '@/explore/components/canvas/types';

// Returns the position (top|right|bottom|right) compared to the passed node
function getParams(nodeA: InternalNode, nodeB: InternalNode) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);
  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);
  let position;

  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position] as const;
}

function getHandleCoordsByPosition(node: InternalNode, handlePosition: Position) {
  // All handles are from type source, that's why we use handleBounds.source here
  const handle = node.internals.handleBounds?.source?.find((h) => h.position === handlePosition);

  if (!handle) {
    return [0, 0];
  }

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
  }

  const x = node.internals.positionAbsolute.x + handle.x + offsetX;
  const y = node.internals.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function getNodeCenter(node: InternalNode) {
  return {
    x: node.internals.positionAbsolute.x + (node.measured?.width ?? 0) / 2,
    y: node.internals.positionAbsolute.y + (node.measured?.height ?? 0) / 2,
  };
}

// Returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) needed to create an edge
export function getEdgeParams(source: InternalNode, target: InternalNode) {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

export interface ConvertCellsToNodesParams {
  rows: Cell[][];
  accountId: string;
  exploration: Exploration;
  parameters: ExplorationParameters;
  isDesktopView: boolean;
  variables: any[];
  selectedCellIdx?: number | null;
  indexCalc: (rows: Cell[][], rowIndex: number, cellIndex: number) => number;
}

export const canvasNodeType = 'canvasBlock';

/**
 * Enhances cells with data that is necessary for CanvasNode to pass on as props into ExplorationCell
 */
export const convertCellsToNodes = ({
  rows,
  accountId,
  exploration,
  parameters,
  isDesktopView,
  variables,
  selectedCellIdx,
  indexCalc,
}: ConvertCellsToNodesParams): CanvasNode[] => {
  return rows
    .map((cells, rowIndex) =>
      cells.map((cell, cellIndex) => {
        const rowHeight = cell.viewOptions?.height ?? 400;
        const absoluteCellIndex = indexCalc(rows, rowIndex, cellIndex);

        return {
          id: cell.id,
          resizing: true,
          // TODO: Get center of canvas
          position: { x: cellIndex * 600 + 16, y: rowIndex * rowHeight + 32 },
          measured: { width: 600, height: rowHeight },
          selected: absoluteCellIndex === selectedCellIdx,
          data: {
            cell,
            cellIndex: absoluteCellIndex,
            accountId,
            exploration,
            parameters,
            isDesktopView,
            variables,
          },
        };
      }),
    )
    .flat();
};

const defaultProperties = {
  data: {},
  type: canvasNodeType,
  resizing: true,
  position: { x: 0, y: 0 },
  measured: { width: 600, height: 400 },
};

/**
 * Merges exploration nodes with persisted options
 */
export const unifyNodes = (nodes: Node[], options: PersistedOptions['nodes'] = []): Node[] => {
  return nodes.map((node) => {
    const nodeOpts = options?.find((n) => n.id === node.id) ?? {};

    return {
      ...defaultProperties,
      ...node,
      ...nodeOpts,
    };
  });
};

export const transformNodesToPersisted = (nodes: Node[]) => {
  return nodes.map((node) => ({
    id: node.id,
    position: node.position,
    measured: node.measured,
  }));
};
