import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand } from '@visx/scale';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';

import { ScaleLinear, ScaleOrdinal } from 'd3-scale';

import { truncate } from 'lodash';

import { ChartTooltipSingleValue } from '../common';
import { CategoryData } from '../grouped-chart/types';

import commonStyles, { gridLineColor } from '../charts.module.scss';
import styles from './horizontal-bar-chart.module.scss';

type TooltipData = {
  categoryLabel: string;
  categoryValue: string;
  valueLabel: string;
  valueKey: string;
  value: number;
  color: string;
};

interface HorizontalBarChartProps {
  categoryFormat: {
    precision?: string;
  };
  onCategroryClick?: (event: React.MouseEvent, key: string, value: any) => void;
  data: CategoryData;
  width: number;
  valueScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
  height?: number;
}

const MaxHeight = 600;
const GroupWidth = parseInt(commonStyles.widthPerGroup);
const CategoryPadding = 0.3;
const BarsInnerPadding = 0.4;
const BarMinHeight = 3;
const BarMaxHeight = 30;
const CategoryLabelMaxLength = 20; // Should be adjusted with the group width

export const HorizontalBarChart = ({
  data,
  width,
  colorScale,
  valueScale,
  onCategroryClick,
  height: chartHeight,
}: HorizontalBarChartProps) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
  };

  const barsCount = data.items.length * data.series.length;
  const height = chartHeight ?? Math.min(barsCount * BarMaxHeight, MaxHeight);

  const categoryScale = scaleBand({
    domain: data.items.map((d) => d.categoryValue),
    padding: CategoryPadding,
    range: [0, height],
  });

  const scaleBandWidth = categoryScale.bandwidth();
  const maxBarsInCategory = Math.max(...data.items.map((item) => Object.keys(item.values).length));
  const barHeight = Math.min(
    BarMaxHeight,
    Math.max(BarMinHeight, scaleBandWidth / maxBarsInCategory / (1 + BarsInnerPadding)),
  );
  const keys = data.series.map(({ key }) => key);

  return (
    <div className={commonStyles.graph}>
      {tooltipData !== undefined ? (
        <ChartTooltipSingleValue
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={{
            label: tooltipData.categoryValue,
            value: tooltipData.value,
          }}
          tooltipOpen={tooltipOpen}
          categoryLabel={tooltipData.categoryLabel}
          valueLabel={tooltipData.valueLabel}
          seriesColor={colorScale(tooltipData.valueKey)}
        />
      ) : null}
      <svg width={width} height={height}>
        <Group left={GroupWidth}>
          <GridColumns width={width} height={height} scale={valueScale} stroke={gridLineColor} />
          {data.items.flatMap((item, idx) => {
            const keysWithValues = keys.filter((key) => item.values[key] !== undefined);
            const valuesCount = keysWithValues.length;
            const barsHeight =
              barHeight * valuesCount + BarsInnerPadding * (valuesCount - 1) * barHeight;
            const y =
              (categoryScale(item.categoryValue) ?? 0) + scaleBandWidth / 2 - barsHeight / 2;

            return keysWithValues.map((key, barIdx) => (
              <BarRounded
                key={`${idx}-${key}`}
                x={0}
                y={y + barIdx * (barHeight + BarsInnerPadding * barHeight)}
                width={valueScale(item.values[key])}
                height={barHeight}
                radius={4}
                right
                fill={colorScale(key)}
                onMouseMove={(e) =>
                  handleMouseOver(e, {
                    value: item.values[key],
                    valueKey: key,
                    categoryLabel: item.label ?? '',
                    categoryValue: item.categoryValue ?? '',
                    valueLabel: data.series.find((series) => series.key === key)?.label ?? key,
                    color: colorScale(key),
                  })
                }
                onMouseOut={hideTooltip}
                onClick={(e) => {
                  onCategroryClick?.(e, item.key, item.categoryValue);
                }}
              />
            ));
          })}
          <AxisLeft
            scale={categoryScale}
            stroke={gridLineColor}
            hideTicks
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text {...tickProps} className={styles.axisLabel}>
                <title>{formattedValue}</title>
                {truncate(formattedValue, { length: CategoryLabelMaxLength })}
              </text>
            )}
            tickValues={categoryScale.domain()} // Always render all tick values
          />
        </Group>
      </svg>
    </div>
  );
};
