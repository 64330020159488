import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import classNames from 'classnames';
import {
  Scanner,
  isPenguinoError,
  isInvalidArgumentError,
  translateToPrql,
  Variables,
  Token,
  serializeTokens,
} from '@gosupersimple/penguino';

import { Field, Model } from '@/explore/types';
import { FieldGroup, isField, isFieldGroup } from '@/explore/pipeline/utils';
import {
  adjustForHumanizedPenguino,
  convertFieldsForPenguinoContext,
  dehumanizeExpressionTokens,
  humanizeExpressionTokens,
} from '@/explore/utils/penguino';

import { TextArea } from '../text-area';
import { PenguinoAutoComplete } from './penguino-autocomplete';
import { SyntaxHighlighting, SyntaxIssue } from './syntax-highlighting';
import { FormInputSize } from '../types';
import { invalidArgumentErrorMessage } from './utils';

import styles from './penguino-input.module.scss';

export const PenguinoVersion = '1';

interface PenguinoInputProps {
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  required?: boolean;
  rows?: number;
  fields: (Field | FieldGroup)[];
  variables: Variables;
  model?: Model;
  onChange: (value: string) => void;
  requiredType?: string;
  className?: string;
  size?: FormInputSize;
}

export const PenguinoInput = (props: PenguinoInputProps) => {
  const [caretPosition, setCaretPosition] = useState<number | null>(null);
  const [forcedCaretPosition, setForcedCaretPosition] = useState<number | null>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [pointerHoverPosition, setPointerPosition] = useState<{ x: number; y: number } | null>(
    null,
  );
  const setPointerHoverPosition = useDebounceCallback(setPointerPosition, 250);

  const preRef = useRef<HTMLPreElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const syncScroll = (e: React.UIEvent<HTMLTextAreaElement>) => {
    if (!preRef.current) {
      return;
    }
    preRef.current.scrollTop = e.currentTarget.scrollTop;
  };

  // Add default fields to context in a keyless group so both `prop(myField)` and `prop(myTable.myField)`
  // become `prop("My Field (on My Table)")`
  const defaultFieldGroup = props.fields
    .filter(isFieldGroup)
    .find((group) => group.key === props.model?.modelId);

  const penguinoFields = useMemo(
    () =>
      convertFieldsForPenguinoContext([
        ...props.fields.filter(isFieldGroup),
        { fields: props.fields.filter(isField) },
        ...(defaultFieldGroup !== undefined ? [{ ...defaultFieldGroup, key: undefined }] : []),
      ]),
    [props.fields, defaultFieldGroup],
  );

  const tokens = useMemo(
    () => new Scanner(String(props.value ?? ''), { safe: true }).scanTokens(),
    [props.value],
  );

  const humanizedTokens = useMemo(
    () => humanizeExpressionTokens(tokens, penguinoFields),
    [tokens, penguinoFields],
  );

  const issues: SyntaxIssue[] = useMemo(() => {
    const value = String(props.value ?? '');
    if (value === '') {
      return [];
    }

    try {
      const { returnType } = translateToPrql(value, {
        fields: penguinoFields,
        variables: props.variables,
      });

      if (props.requiredType !== undefined && returnType !== props.requiredType) {
        throw new Error(`Expected type ${props.requiredType}, got ${returnType}`);
      }

      return [];
    } catch (e) {
      if (isInvalidArgumentError(e)) {
        return (e.closestMatch?.arguments ?? [])
          .filter((arg) => arg.matchingType === false)
          .map((arg) =>
            adjustForHumanizedPenguino(
              {
                name: e.name,
                message: invalidArgumentErrorMessage(arg),
                line: arg.node?.expression.revert().line ?? 1,
                start: arg.node?.expression.revert().start ?? 0,
                end: arg.node?.expression.revert().end ?? value.length,
              },
              tokens,
              penguinoFields,
            ),
          );
      }
      if (isPenguinoError(e)) {
        return [adjustForHumanizedPenguino(e, tokens, penguinoFields)];
      }
      if (e instanceof Error) {
        return [{ message: e.message, line: 1, start: 0, end: value.length }];
      }
      return [{ message: 'Unknown syntax error', line: 1, start: 0, end: 0 }];
    }
  }, [props.value, props.variables, props.requiredType, penguinoFields, tokens]);

  const handleCaretMove = () => {
    setCaretPosition(inputRef.current?.selectionStart ?? caretPosition);
  };

  const handleAutoComplete = (output: Token[], caretPosition: number) => {
    props.onChange(serializeTokens(dehumanizeExpressionTokens(output, penguinoFields)));
    setCaretPosition(caretPosition);
    setForcedCaretPosition(caretPosition);
  };

  const forceCaretPosition = (position: number) => {
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(position, position);
  };

  const handleBlur = () => {
    if (forcedCaretPosition === null) {
      setInputFocused(false);
    }
  };

  // Force caret position and focus after autocompletion via keyboard
  useEffect(() => {
    if (forcedCaretPosition !== null) {
      // setTimeout is necessary to force focus after rendering and any blur event (which happens later)
      setTimeout(() => forceCaretPosition(forcedCaretPosition), 0);
      setForcedCaretPosition(null);
    }
  }, [forcedCaretPosition]);

  // Value and caret position are updated separately so they are out of sync in brief moments
  const constrainedCaretPosition = Math.min(
    caretPosition ?? 0,
    serializeTokens(humanizedTokens).length,
  );

  const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) =>
    setPointerHoverPosition({ x: event.clientX, y: event.clientY });

  const handleMouseLeave = () => setPointerHoverPosition(null);

  return (
    <>
      <div
        className={classNames(styles.penguinoInput, {
          [styles.sizeSmall]: props.size === 'small',
          [styles.sizeMedium]: props.size === 'regular',
          [styles.sizeLarge]: props.size === 'large',
        })}
        onMouseMove={handleMouseOver}
        onMouseLeave={handleMouseLeave}>
        <SyntaxHighlighting
          tokens={humanizedTokens}
          issues={issues}
          preRef={preRef}
          pointerHoverPosition={pointerHoverPosition}
        />

        <TextArea
          value={serializeTokens(humanizedTokens)}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          required={props.required}
          rows={props.rows}
          className={classNames(props.className, styles.editableInput)}
          onScroll={syncScroll}
          spellCheck={false}
          highlighted={inputFocused}
          onFocus={() => setInputFocused(true)}
          onBlur={handleBlur}
          onChange={(e) =>
            props.onChange(
              serializeTokens(
                dehumanizeExpressionTokens(
                  new Scanner(String(e.target.value), { safe: true }).scanTokens(),
                  penguinoFields,
                ),
              ),
            )
          }
          onKeyDown={handleCaretMove}
          onKeyUp={handleCaretMove}
          onMouseDown={handleCaretMove}
          onMouseUp={handleCaretMove}
          ref={inputRef}
        />
      </div>
      {inputFocused && (
        <PenguinoAutoComplete
          tokens={humanizedTokens}
          caretPosition={constrainedCaretPosition}
          onSelect={handleAutoComplete}
          fields={props.fields}
          inputRef={inputRef}
        />
      )}
    </>
  );
};
