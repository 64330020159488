import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Token, TokenType } from '@gosupersimple/penguino';

import { Tooltip } from '../../tooltip';

import styles from './penguino-input.module.scss';
import syntaxStyles from './syntax-highlighting.module.scss';

export interface SyntaxIssue {
  message: string;
  line: number;
  start: number;
  end: number;
}

const isIssueWithinToken = (issue: SyntaxIssue, token: Token) =>
  issue.line === token.line && issue.start <= token.start && token.end <= issue.end;

const findIssueByToken = (token: Token, issues: SyntaxIssue[]) =>
  issues.find((i) => isIssueWithinToken(i, token));

interface SyntaxHighlightingProps {
  tokens: Token[];
  issues: SyntaxIssue[];
  preRef: React.RefObject<HTMLPreElement>;
  className?: string;
  pointerHoverPosition: { x: number; y: number } | null;
}

export const SyntaxHighlighting = (props: SyntaxHighlightingProps) => {
  const { issues, preRef, className, pointerHoverPosition } = props;

  const tokens = useMemo(() => {
    const lineBreak = props.tokens.at(props.tokens.length - 2);
    return lineBreak?.lexeme !== '\n'
      ? props.tokens
      : // Add a space so the <pre> tag wouldn't hide the empty last line.
        [
          ...props.tokens.slice(0, props.tokens.length - 1),
          new Token(
            TokenType.Whitespace,
            ' ',
            ' ',
            lineBreak.line,
            lineBreak.end,
            lineBreak.end + 1,
          ),
          props.tokens[props.tokens.length - 1],
        ];
  }, [props.tokens]);

  const [issue, setIssue] = useState<string | null>(null);

  const preRect = preRef.current?.getBoundingClientRect();

  useEffect(() => {
    if (pointerHoverPosition === null) {
      setIssue(null);
      return;
    }

    const issue = Array.from(preRef.current?.querySelectorAll('span') ?? [])
      .map((span) => {
        const rect = span.getBoundingClientRect();
        if (
          pointerHoverPosition.x >= rect.left &&
          pointerHoverPosition.x <= rect.right &&
          pointerHoverPosition.y >= rect.top &&
          pointerHoverPosition.y <= rect.bottom
        ) {
          return span.getAttribute('data-message') ?? undefined;
        }
      })
      .filter((i) => i !== undefined)
      .at(-1);

    setIssue(issue ?? null);
  }, [pointerHoverPosition, preRef]);

  const tokensWithIssues = tokens.map<[Token, string?]>((token) => [
    token,
    findIssueByToken(token, issues)?.message,
  ]);

  return (
    <>
      <pre className={classNames(styles.highlightedOutput, className)} ref={preRef}>
        {tokensWithIssues.map(([token, issue], i) => (
          <span
            key={i}
            className={classNames({
              [syntaxStyles.identifierToken]: token.type === TokenType.Identifier,
              [syntaxStyles.numberToken]: token.type === TokenType.Number,
              [syntaxStyles.stringToken]: token.type === TokenType.String,
              [syntaxStyles.punctuationToken]: token.type === TokenType.Comma,
              [syntaxStyles.booleanToken]:
                token.type === TokenType.True || token.type === TokenType.False,
              [syntaxStyles.nullToken]: token.type === TokenType.Null,
              [syntaxStyles.error]: issue !== undefined,
            })}
            data-message={issue}>
            {token.lexeme}
          </span>
        ))}
      </pre>

      {issue !== null && (
        <Tooltip
          size="compact"
          color="error"
          top={preRect?.top ?? 0}
          left={0}
          offsetLeft={0}
          offsetTop={-28}
          bound="x"
          boundToRef={preRef}
          className={styles.errorTooltip}>
          {issue}
        </Tooltip>
      )}
    </>
  );
};
