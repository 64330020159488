import { omit } from 'lodash';

import { useTrackEvent } from '@/lib/analytics';

import { Exploration } from '../types';
import { Button, IconButton } from '../../components/button';
import { Dropdown, DropdownMenuItem } from '../../components/dropdown';
import { Icon } from '../../components/icon';
import { createBackNavigationToast, useToastContext } from '../../components/toast';
import { useExplorationCellContext } from './exploration-cell-context';
import { useExplorationContext } from './exploration-context';
import { isCellFirstInRow, isCellLastInRow } from './exploration-layout/utils';

import styles from './exploration.module.scss';

export type CellOption = DropdownMenuItem & {
  sort: number;
};

interface CellControlsProps {
  exploration: Exploration;
  editButtonVisible?: boolean;
  canDelete?: boolean;
  canDuplicate?: boolean;
  options?: (defaultOptions: CellOption[]) => CellOption[];
  children?: React.ReactNode;
}

export const CellControls = (props: CellControlsProps) => {
  const {
    exploration,
    editButtonVisible = false,
    canDelete = true,
    canDuplicate = true,
    options = (defaultOptions) => defaultOptions,
    children,
  } = props;

  const {
    cellCount,
    isEditorOpen,
    isCanvasView,
    openEditor,
    closeEditor,
    duplicateCell,
    deleteCell,
    selectCell,
    deselectCell,
    scrollToCell,
    moveCellUp,
    moveCellDown,
  } = useExplorationContext();
  const { cell, cellIndex, isSelectedCell } = useExplorationCellContext();
  const addToast = useToastContext();
  const trackEvent = useTrackEvent();

  const handleDuplicateCell = () => {
    duplicateCell(cellIndex);
    selectCell(cellIndex + 1);
    scrollToCell(cellIndex + 1);

    trackEvent('Exploration Cell Duplicated', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const handleDeleteCell = () => {
    deleteCell(cell.id);
    deselectCell();

    addToast(
      createBackNavigationToast(
        'Block Deleted',
        "If you didn't mean to delete it, you can use your browser back button or",
        'click undo',
      ),
    );

    trackEvent('Exploration Cell Deleted', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const handleMoveUp = () => {
    moveCellUp(cellIndex);
    trackEvent('Exploration Cell Moved Up', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const handleMoveDown = () => {
    moveCellDown(cellIndex);
    trackEvent('Exploration Cell Moved Down', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const isFirst = isCellFirstInRow(exploration.view.cells, cellIndex);
  const isLast = isCellLastInRow(exploration.view.cells, cellIndex);

  const items: DropdownMenuItem[] = options([
    {
      label: 'Duplicate block',
      icon: <Icon name="Duplicate" size={16} />,
      onClick: handleDuplicateCell,
      sort: 10,
      disabled: !canDuplicate,
    },
    {
      type: 'divider',
      sort: 90,
    },
    {
      label: 'Delete block',
      icon: <Icon name="Trash2" size={16} />,
      onClick: handleDeleteCell,
      sort: 95,
      disabled: !canDelete || cellCount === 1,
    },
    {
      type: 'divider',
      sort: 95,
    },
    ...(isCanvasView
      ? []
      : [
          {
            label: isFirst ? 'Move up' : 'Move left',
            icon: <Icon name={isFirst ? 'ArrowUp' : 'ArrowLeft'} size={16} />,
            onClick: handleMoveUp,
            sort: 100,
            disabled: cellIndex === 0 && isLast,
          },
          {
            label: isLast ? 'Move down' : 'Move right',
            icon: <Icon name={isLast ? 'ArrowDown' : 'ArrowRight'} size={16} />,
            onClick: handleMoveDown,
            sort: 110,
            disabled: cellIndex === cellCount - 1 && isFirst,
          },
        ]),
  ])
    .sort((a, b) => a.sort - b.sort)
    .map((item) => omit(item, ['sort']) as DropdownMenuItem);

  return (
    <div className={styles.cellControls}>
      {children}
      <Dropdown
        align="right"
        trigger={(isOpen, setIsOpen) => (
          <IconButton
            icon="MoreHorizontal"
            size="small"
            title="More..."
            type="gray"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
        items={items}
      />
      {editButtonVisible &&
        (isEditorOpen && isSelectedCell ? (
          <Button variant="secondary" size="small" onClick={closeEditor}>
            Done
          </Button>
        ) : (
          <Button size="small" onClick={() => openEditor({ cellIndex })}>
            Edit
          </Button>
        ))}
    </div>
  );
};
